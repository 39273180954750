:root {
  --clr-1: #111111;
  --clr-2: #ffffff;

  --clr-3: #b85757;
  --clr-4: #b8575770;

  --clr-p2: #ffffff70;
  --clr-p1: #11111170;
}

@font-face {
  font-family: myFont;
  src: url(./font/MiddleEarth.otf);
}

@font-face {
  font-family: myFont2;
  src: url(./font/MetalMania.ttf);
}

h1,
h2,
h3,
h4,
h5,
p,
a,
li,
button {
  font-family: "myFont", sans-serif;
}

p,
a,
li,
button {
  font-family: "myFont2", sans-serif;
}

h1 {
  font-size: 8rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: -1rem;
}
h2 {
  font-size: 6rem;
  font-weight: 600;
}
h3 {
  font-size: 2rem;
  font-weight: 600;
}
h5 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--clr-3);
}

p,
li,
a,
button {
  font-size: 1.2rem;
  font-weight: 400;
}

a {
  color: var(--clr-2);
  transition: 0.3s;
}
a:hover {
  color: var(--clr-3);
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
:root {
  overflow-x: hidden;
}
body {
  position: relative;

  font-size: 1rem;
  font-weight: 400;
  font-family: "myFont", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  color: var(--clr-2);
  background-color: var(--clr-1);

  overflow-x: hidden;
}
.bg-blur-01 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: calc(50% - 100px);

  width: 200px;
  height: 200px;

  background-color: var(--clr-3);
  filter: blur(200px);
}
.bg-blur-02 {
  position: absolute;
  z-index: -1;
  top: 40%;
  left: -200px;

  width: 400px;
  height: 400px;

  background-color: var(--clr-3);
  filter: blur(300px);
}
.bg-blur-03 {
  position: absolute;
  z-index: -1;
  top: 60%;
  right: -200px;

  width: 400px;
  height: 400px;

  background-color: var(--clr-3);
  filter: blur(300px);
}
.section {
  width: 100%;
  padding: 8rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  padding: 0.2rem 3rem;

  transition: 300ms;

  border-radius: 5px;
}
button:hover {
  transform: translateY(+5px);

  cursor: pointer;
}

.btnFlex {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
}
.btn01 {
  color: var(--clr-2);
  background-color: var(--clr-4);
  border: solid 1px var(--clr-3);
  border-radius: 5px;
}
.btn02 {
  color: var(--clr-2);
  background-color: transparent;
  border: solid 1px var(--clr-2);
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 0.5rem;

  background: var(--clr-1);
}
::-webkit-scrollbar-track {
  border: none;
}
::-webkit-scrollbar-thumb {
  border: solid 2px var(--clr-1);
  border-radius: 1rem;

  background: var(--clr-2);

  cursor: grab;
}

/*-- responsive --*/
@media screen and (max-width: 1300px) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2rem;
  }

  .container {
    width: 900px;
  }
}
@media screen and (max-width: 1000px) {
  h1 {
    font-size: 16vw;
  }
  h2 {
    font-size: 8vw;
  }
  h3 {
    font-size: 6vw;
  }

  .section {
    padding: 3rem 1rem;
  }
  .container {
    width: 100%;
  }

  button {
    padding: 0.5rem 1rem;
  }

  .btnFlex a,
  .btnFlex button {
    width: 100%;
  }
  .btnFlex {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .btnFlex a,
  .btnFlex button {
    width: 100%;
  }
}
