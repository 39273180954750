.HowTo01 {
  padding: 5rem 1rem 20rem;

  background-color: var(--clr-4);
}

.HowTo01-box {
  position: relative;
  width: 100%;
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 2.66rem));
  gap: 4rem;
  justify-content: start;
  align-items: start;
}

.HowTo01-box > img {
  position: absolute;
  bottom: -20rem;
  left: 0;

  width: 1200px;
}

.HowTo01-box > div {
  width: 100%;
  height: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;

  background-color: var(--clr-p1);
  border: solid 1px var(--clr-2);
  border-radius: 5px;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .HowTo01-box > img {
    position: absolute;
    bottom: -20rem;
    left: 0;

    width: 900px;
  }
}

@media screen and (max-width: 1000px) {
  .HowTo01 {
    padding: 3rem 1rem 9rem;
  }

  .HowTo01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2rem;
  }
  .HowTo01-box > img {
    position: absolute;
    bottom: -9rem;
    left: -10%;

    width: 120%;
  }
}
