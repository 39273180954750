.Join01 {
  padding: 0 1rem 8rem;
}

.Join01-box {
  width: 900px;
  padding: 2rem 4rem;

  background-color: var(--clr-4);
  border: solid 1px var(--clr-3);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  text-align: center;
}
.Join01-box > p {
  color: var(--clr-p2);
}

.Join01-box > .btnFlex {
  width: 100%;
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Join01 {
    padding: 0 1rem 3rem;
  }
  .Join01-box {
    width: 100%;
    padding: 2rem 2rem;
  }
  .Join01-box > .btnFlex {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}
