.About01 {
  text-align: center;
}

.About01-box {
  width: 100%;
  aspect-ratio: 6/4;

  padding: 2rem 10rem;

  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5rem));
  justify-content: center;
  align-items: center;
  gap: 10rem;

  background-image: url(../img/cont/book-01.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.About01-box p {
  font-size: 1.6rem;
  color: var(--clr-1);
}

.About01-box > div {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .About01-box {
    width: 100%;
    aspect-ratio: 6/4;

    padding: 2rem 8rem;

    display: grid;
    grid-template-columns: repeat(2, calc(50% - 4rem));
    justify-content: center;
    align-items: center;
    gap: 8rem;

    background-image: url(../img/cont/book-01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .About01-box p {
    font-size: 1.2rem;
    color: var(--clr-1);
  }
}

@media screen and (max-width: 1000px) {
  .About01-box {
    width: 110%;
    aspect-ratio: unset;

    padding: 5rem 2rem 6rem 5rem;

    display: grid;
    grid-template-columns: 100%;
    justify-content: start;
    align-items: start;
    gap: 2rem;

    background-image: url(../img/cont/book-01.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .About01-box > div {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: left;
  }
}
