.Hero01 {
  padding: 8rem 1rem 0;
  background-image: url(../img/bg/bg-02.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Hero01-box {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.Hero01-box > div:nth-child(1) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  text-align: center;
}
.Hero01-box img {
  width: 900px;
  margin-top: 0;
}

/*-- responsive --*/
@media screen and (max-width: 1300px) {
  .Hero01-box {
  }
}

@media screen and (max-width: 1000px) {
  .Hero01 {
    padding: 3rem 1rem 0;
  }

  .Hero01-box {
  }

  .Hero01-box img {
    width: 100%;
    margin-top: 0;
  }
}
