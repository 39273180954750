.Token01 {
  padding: 0 1rem 8rem;
}

.Token01-box {
  width: 100%;
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 3.33rem));

  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.Token01-box > div {
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  text-align: center;

  background-color: var(--clr-1);
  border: solid 1px var(--clr-p2);
  border-radius: 5px;
}

.Token01-box > div:nth-child(1) > div,
.Token01-box > div:nth-child(3) > div {
  width: 100%;
  padding: 0.5rem 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  background-color: var(--clr-4);
  border: solid 1px var(--clr-3);
  border-radius: 5px;
}

.Token01-box > div:nth-child(2) > div {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.Token01-box > div:nth-child(2) > .btnFlex {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Token01-box > div:nth-child(2) > div.ca {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0;
}
.Token01-box > div:nth-child(2) > div.ca p {
  width: 100%;
  padding: 0.5rem;

  overflow: hidden;

  background-color: var(--clr-3);
  border-radius: 5px 0 0 5px;
}
.Token01-box > div:nth-child(2) > div.ca button {
  padding: 0;

  background-color: var(--clr-2);
  border: none;
  border-radius: 0 5px 5px 0;
}
.Token01-box > div:nth-child(2) > div.ca button:hover {
  transform: unset;
}
.Token01-box > div:nth-child(2) > div.ca button:hover img {
  height: 0.9rem;
  margin: 0.75rem 1.15rem;
}
.Token01-box > div:nth-child(2) > div.ca button img {
  height: 1.2rem;
  margin: 0.6rem 1rem;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .Token01-box {
    width: 100%;
    margin-top: 2rem;

    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 1.33rem));

    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .Token01 {
    padding: 0 1rem 3rem;
  }

  .Token01-box {
    width: 100%;

    display: grid;
    grid-template-columns: 100%;

    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .Token01-box > div:nth-child(2) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
}
