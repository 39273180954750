.Token02 {
  padding: 0 1rem 8rem;
}

.Token02-box {
  width: 900px;
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.Token02-box > div {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Token02-box > div.ca {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0;
}
.Token02-box > div.ca p {
  width: 100%;
  padding: 0.5rem;

  overflow: hidden;

  background-color: var(--clr-3);
  border-radius: 5px 0 0 5px;
}
.Token02-box > div.ca button {
  padding: 0;

  background-color: var(--clr-2);
  border: none;
  border-radius: 0 5px 5px 0;
}
.Token02-box > div.ca button:hover {
  transform: unset;
}
.Token02-box > div.ca button:hover img {
  height: 0.9rem;
  margin: 0.75rem 1.25rem;
}
.Token02-box > div.ca button img {
  height: 1.4rem;
  margin: 0.5rem 1rem;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .Token02-box {
    width: 900px;
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .Token02 {
    padding: 0 1rem 3rem;
  }

  .Token02-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
  }
}
